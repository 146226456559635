<template>
  <t-modal
    :name="popName"
    :variant="`transaction`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <section class="px-8 pt-10">
      <div class="font-medium text-28px text-oCharcoal">
        {{ popTitle }}
      </div>

      <div class="p-8">
        <label class="mt-8">
          <input
            type="number"
            class="w-full h-16 px-3 text-2xl font-bold text-center text-orange-400 border rounded focus:outline-none"
            v-model.number="adjustmentBalance"
            :placeholder="getAmountPlaceholder"
            ref="adjustBalanceInput"
          />
          <!-- <span
            class="flex items-center justify-center mt-2 text-sm font-bold text-center text-gray-800"
          >
            Current Balance:{{ currencySymbol }}{{ data.currentBalance }}
          </span> -->
        </label>

        <AppInput
          v-model="targetAccount"
          type="richselect"
          name="target"
          label="Target Account"
          :options="wallets"
          class="mt-8"
          hide-search-box
        />

        <label class="block w-full mt-6 text-sm font-bold text-gray-800">
          Adjusted Balance ({{ getCurrencySymbol }})
          <input
            type="text"
            class="w-full px-2 py-2 text-gray-500 bg-gray-200 border-gray-300 rounded focus:outline-none"
            :value="getCurrentBalance"
            readonly
          />
        </label>

        <div class="mt-8 single-input">
          <label class="w-full text-sm font-bold text-gray-800">
            Adjusting balance of
            <input
              type="text"
              class="w-full px-2 py-2 text-gray-500 bg-gray-200 border-gray-300 rounded focus:outline-none"
              :value="`${data.fullName} (${data.phoneNumber})`"
              readonly
            />
          </label>
        </div>

        <section class="mt-6">
          <t-checkbox
            wrapped
            v-model="remarksUi"
            :label="`Add Remarks`"
            :name="`adminRemarksUi`"
          />
          <div class="-mt-2 text-14px" style="margin-left: 28px;">
            <span class="text-gray-500">
              Adding remarks is optional but recommended.
            </span>
          </div>
        </section>

        <section v-show="remarksUi" class="mt-6">
          <t-textarea v-model="remarks" />
        </section>

        <div class="flex items-center justify-end mt-12 mb-4 space-x-4">
          <AppButton
            :variant="`secondary`"
            :text="`Cancel`"
            @click="$refs.popup.hide()"
          />

          <AppButton
            :variant="getSubmitButtonVariant"
            :text="getSubmitButtonText"
            :isLoading="isLoading"
            @click="submitForm"
          />
        </div>
      </div>
    </section>
  </t-modal>
</template>

<script>
import { EventBus } from '@/utils/EventBus'

export default {
  name: 'AdjustBalancePopup',
  components: {
    // AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    // TextInput: () => import('@/components/form/TextInput.vue'),
    // DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    wallets: {
      type: Array,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    popName: 'AdjustBalancePopup',
    isLoading: false,
    adjustmentBalance: null,
    targetAccount: null,
    remarks: '',
    remarksUi: false,
  }),
  computed: {
    popTitle() {
      if (this.data.type === 'add') {
        return `Add Balance`
      }
      if (this.data.type === 'deduct') {
        return `Deduct Balance`
      }
      return `Adjust Balance`
    },
    getSubmitButtonText() {
      if (this.data.type === 'add') {
        return `Add Balance`
      }
      if (this.data.type === 'deduct') {
        return `Deduct Balance`
      }
      return `Adjust Balance`
    },
    getSubmitButtonVariant() {
      if (this.data.type === 'add') {
        return `success`
      }
      if (this.data.type === 'deduct') {
        return `warning`
      }
      return `primary`
    },
    getSelectedWallet() {
      return this.wallets.find((wallet) => wallet.id === this.targetAccount)
    },
    getCurrencySymbol() {
      return (
        this.getSelectedWallet?.currency?.symbol || this.currencySymbol || ''
      )
    },
    getCurrentBalance() {
      const currBal = Number(this.getSelectedWallet?.balance)
      let res
      if (this.data.type === 'add') {
        res = currBal + this.adjustmentBalance
      }
      if (this.data.type === 'deduct') {
        res = currBal - this.adjustmentBalance
      }
      return res ? res.toFixed(2) : currBal
    },
    // allowing negative values both in add & deduct balance
    getAdjustAmountForSubmission() {
      if (this.data.type === 'deduct' && this.adjustmentBalance > 0) {
        return `-${this.adjustmentBalance}`
      }
      return this.adjustmentBalance
    },
    getAdjustAmountForToast() {
      const sign =
        this.data.type === 'deduct' && this.adjustmentBalance > 0 ? '-' : ''

      // remove the sign
      const amount = this.adjustmentBalance.toString().replace(/^-/g, '')

      return `${sign}${this.currencySymbol}${amount}`
    },

    getAmountPlaceholder() {
      const selected = this.wallets.find(
        (wallet) => wallet.id === this.targetAccount
      )
      if (selected) {
        return `${selected.currency.symbol} 0.00`
      }
      return this.currencySymbol + ' 0.00'
    },
  },
  async mounted() {
    EventBus.$on(this.$config.riderUser.events.adjustBalancePop, async () => {
      setTimeout(() => {
        if (this.$refs.adjustBalanceInput) {
          this.$refs.adjustBalanceInput.focus()
          const def = this.wallets.find((wallet) => wallet.default === true)
          if (def) {
            this.targetAccount = def.id
          }
        }
      }, 500)
    })
  },
  methods: {
    submitForm() {
      if (!this.adjustmentBalance && this.$refs.adjustBalanceInput) {
        this.$refs.adjustBalanceInput.focus()
        return
      }

      const toastTitle =
        this.data.type === 'add' ? 'Balance Added' : 'Balance Deducted'
      const toastText =
        this.data.type === 'add'
          ? `${this.getAdjustAmountForToast} is added to ${this.data.fullName}'s account`
          : `${this.getAdjustAmountForToast} is deducted from ${this.data.fullName}'s account`

      const formData = new FormData()
      formData.append('balance_adjustment', this.getAdjustAmountForSubmission)
      formData.append('target_account', this.targetAccount)
      formData.append('remarks', this.remarks)

      const endpoint = this.$config.riderUser.api.balanceAdjustment(
        this.data.userId
      )

      this.isLoading = true
      this.$http
        .post(endpoint, formData)
        .then((res) => {
          // todo: emit event
          // console.log(res)
          const adjustedBalance = res.data.data.balance_after
          this.$emit('adjustedBalance', adjustedBalance)

          this.adjustmentBalance = null
          this.remarksUi = false
          this.remarks = ''

          this.$refs.popup.hide()

          this.$notify({
            group: 'generic',
            type: 'success',
            title: toastTitle,
            text: toastText,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
